import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Modal, Button, Form, Select, message } from "antd";
const {Option} = Select;
const optionData = [
	{label: 'Leave At Door', value: 'Leave At Door', disable: false},
	{label: 'Meet At Door', value: 'Meet At Door', disable: false},
	{label: 'Leave On The Porch', value: 'Leave On The Porch', disable: false}
];

const ErrandCheckOutInstructionModal = ({
	errandInfo,
  updateErrandData,
	address,
}) => {

  const [open, setOpen] = useState(false);  
  const [instruction, setInstruction] = useState("");
  const [instructionType, setInstructionType] = useState("");
  
  useEffect(() => {
		//console.log(errandInfo);
	  setInstructionType(errandInfo?.instructionType ?? "");
	  setInstruction(errandInfo?.instruction ?? "");
  }, [errandInfo]);
  
  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "";	
  }, [open]);
  

  const handleSetOption = (value) => {
	  if(!value){message.error('Please select delivery instruction type'); return}
      setInstructionType(value);
			const newErrandData = {
				...errandInfo,
				instructionType: value,
			};
			updateErrandData(newErrandData);
  };
  
  const handleInstruction = (value) => {
      setInstruction(value);
      setInstructionType(instructionType);
	  const newErrandData = {
		  ...errandInfo,
		  instruction: value,
		  instructionType: instructionType,
	  };
	  updateErrandData(newErrandData);  
  };

  const handleContinue = () => {
	  if(!instructionType){message.error('Please select delivery instruction type'); return}
	  setOpen(false);
  };

  const getHeader = () => {
      return "Special Instruction";
  };

  const getButton = () => {
      return [
        <Button key="back" className="c__button" onClick={() => handleContinue()}>
          Continue
        </Button>,
      ];
  };

  return (
    <>
		<div className="_bucj1r" data-name="cart.errandInfo.address.pickup">
          <svg
            className="_17lw9oo"
            aria-label="Delivery icon"
            width="20"
            height="25"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Delivery icon</title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 0C4.0879 0 0.5 3.57642 0.5 7.99164C0.5 9.84522 1.14432 11.6412 2.32265 13.072L7.72807 19.6357C7.91803 19.8664 8.20119 20 8.5 20C8.79881 20 9.08197 19.8664 9.27193 19.6357L14.6773 13.072C15.8557 11.6412 16.5 9.84522 16.5 7.99164C16.5 3.57642 12.9121 0 8.5 0ZM6.5 8C6.5 6.89543 7.39543 6 8.5 6C9.60457 6 10.5 6.89543 10.5 8C10.5 9.10457 9.60457 10 8.5 10C7.39543 10 6.5 9.10457 6.5 8Z"
              fill="#4C4C4C"
            ></path>
          </svg>
          <div className="_1ucqjmf">
            <div>
              <div data-name="delivery-name" className="_15mkp10 _1upj7ie">
                <p data-name="delivery-date-time" className="Bv001L9NJtjGjDwPCRoIt">
									{instructionType}
                </p>
              </div>
              <div className="_1r1eulg">
                <p data-name="errand-address" className="_1IWUl16W8WLdjHSoXcSyru _17y0oyu">
                  {!instruction ? "+ Errand Instruction" : instruction}
                </p>
              </div>
            </div>
            <button
              aria-disabled="false"
              className="_1JdD97nX-WGcPASVXFJXKw _1SEARqWERjoS6g0rgsTmMe _1p5njgr"
              tabIndex="0"
              type="button"
              id="errandInstruction"
              onClick={() => setOpen(true)}
            >
              Edit
            </button>
          </div>
        </div>
		
     
	  
      <Modal
        title={getHeader()}
        open={open}
        onCancel={() => setOpen(false)}
				closable={true}
        maskClosable={false}
        footer={getButton()}
				style={{
					maxHeight: '80vh',
					overflow: 'auto',
				}}
			>
		{/*Change Address Instruction*/}
    
		  <>
          <Form
            style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
			initialValues={{ instruction: instruction, instructionType: instructionType }}
          >
			<Form.Item name="instructionType" label="Instruction Type">
              <Select
                size="large"
                className="cus_selec"
                style={{
                  width: "100%",
                }}
                value={instructionType}
                onChange={(value) => handleSetOption(value)}
              >
                {optionData.map(({ label, value, disable }) => (		  
				  <Option value={value} disabled={disable} key={uuidv4()}>
                    {label}
				  </Option>
				))}
              </Select>
            </Form.Item>
            <Form.Item name="instruction" label="Message to Driver [Optional]">
              <textarea
                className="cus_textarea"
                style={{
                  width: "100%",
                }}
                value={instruction}
                onChange={(e) => handleInstruction(e.target.value)}
              />
            </Form.Item>			
		  </Form>
		  </>
        
		

	  </Modal>
    </>
  );
};

export default ErrandCheckOutInstructionModal;
