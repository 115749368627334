import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { utils } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import InputMask from 'react-input-mask';
import { Divider, message, Skeleton, Button, Space, Alert, Radio } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  PoweroffOutlined
} from "@ant-design/icons";
import {
  AlertModal,
  CheckOutAddressModal,
  OrderModal,
  CheckOutInstructionModal,
  PaymentSummarySection,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSavedStores,
  selectOrderCart,
  selectOrderData,
  selectErrandOrderData,
  setSavedStores,
  setOrderCart,
  setOrderData,
  setErrandOrderData,
  selectSavedCards,
  setSavedCards,
  setPaymentCardData,
} from "../../includes/navSlice";
import { useParams, useNavigate, useLocation } from "react-router-dom";


const appId = utils.squareAppId; //'YOUR_APPLICATION_ID';
const locationId = utils.squareLocationId; //'YOUR_LOCATION_ID';

const ContactSection = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  post,
  emailError,
  manual,
  phoneFormat,
  handleChangePhoneFormat,
}) => {
 
  
  return (
    <div className="sivN0QB-MWF-_Ey25UGyT">
      <div data-testid="userForm" className="">
        <div className="_1Hwy0KjPHG2LqgsRmhddq6">
          <div className="_1EoZthREuSFRJRNxo0vkXz">
            <div
              className="yNIm2lvE_j8Du8jBoXORx"
              data-testid="text_input_wrapper"
            >
              <label className="_2LHIV8HfhKnOukKsXBjlZC" htmlFor="firstName">
                First name
                <input
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  id="firstName"
                  className="_1FZmgafDZ0ZT6CJwZ1-Hbj _3qZUGn_ZPC5Ae0OipBYAoG"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {post && !firstName && (
                  <span style={{ color: "#cc0023", fontSize: 13 }}>
                    First name is required
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="_1EoZthREuSFRJRNxo0vkXz">
            <div
              className="yNIm2lvE_j8Du8jBoXORx"
              data-testid="text_input_wrapper"
            >
              <label className="_2LHIV8HfhKnOukKsXBjlZC" htmlFor="lastName">
                Last name
                <input
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  id="lastName"
                  className="_1FZmgafDZ0ZT6CJwZ1-Hbj _3qZUGn_ZPC5Ae0OipBYAoG"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {post && !lastName && (
                  <span style={{ color: "#cc0023", fontSize: 13 }}>
                    Last name is required
                  </span>
                )}
              </label>
            </div>
          </div>
        </div>
		
        <div>
		
		{((!manual && !phone) || manual) && (<>
          <div className="_1Hwy0KjPHG2LqgsRmhddq6">
            <div className="_1EoZthREuSFRJRNxo0vkXz">
              <div
                className="yNIm2lvE_j8Du8jBoXORx"
                data-testid="text_input_wrapper"
              >
                <label className="_2LHIV8HfhKnOukKsXBjlZC" htmlFor="email">
                  Email address
                  <input
                    name="email"
                    type="email"
                    placeholder="Email address"
                    id="email"
                    className="_1FZmgafDZ0ZT6CJwZ1-Hbj"
					autoComplete="off"
                    value={email}					
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {post && !email && (
                    <span style={{ color: "#cc0023", fontSize: 13 }}>
                      Email is required
                    </span>
                  )}
                  {emailError && (
                    <span style={{ color: "#cc0023", fontSize: 13 }}>
                      Invalid Email
                    </span>
                  )}
                </label>
              </div>
            </div>
			
            <div className="_1EoZthREuSFRJRNxo0vkXz">
              <div
                className="yNIm2lvE_j8Du8jBoXORx"
                data-testid="text_input_wrapper"
              >
                <label className="_2LHIV8HfhKnOukKsXBjlZC" htmlFor="phone">
                  Mobile phone number<span
						className="text-el-red font-semibold"
						onClick={()=> handleChangePhoneFormat()}
						style={{cursor: 'pointer', position: 'absolute', right: 0, textTransform: 'capitalize', top: 0}}
						>Change Format</span
					>               
				  <InputMask
					mask={`${phoneFormat === 'NG' ? '99999999999' : '(999) 999-9999'}`}
					id="phone"
					placeholder={`${phoneFormat === 'NG' ? '08012345678' : "(123) 456-7890'"}`}
                    className="_1FZmgafDZ0ZT6CJwZ1-Hbj"
					autoComplete="off"
                    value={phone}					
                    onChange={(e) => setPhone(e.target.value)}
				  />			  
                  {post && !phone && (
                    <span style={{ color: "#cc0023", fontSize: 13 }}>
                      Mobile phone number is required
                    </span>
                  )}
                </label>
              </div>
            </div>
			
          </div>
		  </>
		  )}
		
		
		
          <div className="_1Hwy0KjPHG2LqgsRmhddq6">
            <div className="YunkBiuQD9vb7_xUcRxd2">
              <div className="xv-JFROHhtCGVgPLzYA6T"></div>
              <div className="VKqQFeqRw6Q1CqdO_U_lC">
                By continuing with checkout you agree to ErrandLocal.{" "}
                <a
                  href="https://errandlocal.com/tos"
                  className="VEAcVxd5m7MukYznhTf_-"
                  target="_blank"
				  rel="noreferrer"
				  style={{color: '#0ecb81'}}
                >
                  Terms of service
                </a>{" "}
                &amp;{" "}
                <a
                  href="https://errandlocal.com/privacy"
                  className="VEAcVxd5m7MukYznhTf_-"
				  target="_blank"
				  rel="noreferrer"
				  style={{color: '#0ecb81'}}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

const CheckOutScreen = ({logPage}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const savedStores = useSelector(selectSavedStores);
  const preOrderCart = useSelector(selectOrderCart);
  const preOrderData = useSelector(selectOrderData);
  const preErrandOrderData = useSelector(selectErrandOrderData);
  const cardData = useSelector(selectSavedCards);
  const cartOrder =
    typeof preOrderCart === "object" ? preOrderCart : JSON.parse(preOrderCart);
  const orderData =
    typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
  const errandOrderData =
    typeof preErrandOrderData === "object" ? preErrandOrderData : JSON.parse(preErrandOrderData);
  const cardSaved =
    typeof cardData === "object" ? cardData : JSON.parse(cardData);	
  
  const sessionemail = utils.getStorage("__el_data_email");
  const auth = utils.getStorage("__el_data_login_token");
  const ellogin = utils.getStorage('__el_data_login_status');
	let elfirstname = utils.getStorage('__el_data_first_name') || "";
	if(elfirstname === 'null' || elfirstname === null || !elfirstname){
		elfirstname = "";
	}
  let ellastname = utils.getStorage('__el_data_last_name') || "";
	if(ellastname === 'null' || ellastname === null || !ellastname){
		ellastname = "";
	}

  const [orderPlaced, setOrderPlaced] = useState([]);
  const [orderCart, setCartOrder] = useState([]);
  const [cards, setCards] = useState(cardSaved);
  const [defaultCard, setDefaultCard] = useState(
    cards?.find((card) => card?.cardMain)?.id || ""
  );
  const [cartLength, setCartLength] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);
  const [fulfillmentType, setFulfillmentType] = useState("");
  const [checkOutError, setCheckOutError] = useState("");
  const [storeId, setStoreId] = useState("");
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newCard, setNewCard] = useState(false);
  const [cartStoreName, setCartStoreName] = useState("");
  const [shopStoreName, setShopStoreName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [servicerate, setServiceRate] = useState(0);
  const [currency, setCurrency] = useState("");
  const [taxrate, setTaxRate] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [post, setPost] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [tipError, setTipError] = useState("");
  const [tip, setTip] = useState(0);
  const [tipPercentage, setTipPercentage] = useState(15);
  const [gps, setGps] = useState({});
  const [shopOperationTime, setShopOperationTime] = useState("");
  const [shopHours, setShopHours] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState(orderData);
  const [errandInfo, setErrandInfo] = useState(errandOrderData);
  const [closeTime, setCloseTime] = useState(0);
  const [storeOpen, setStoreOpen] = useState(false);
  const [shopTimeZone, setShopTimeZone] = useState("");
  const [shopOperationColor, setShopOperationColor] = useState("");
  const [address, setAddress] = useState("");
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartType, setCartType] = useState("");
  const [cartData, setCartData] = useState("");
  const [couponError, setCouponError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [strikeFee, setStrikeFee] = useState(false);
  const [strikeValue, setStrikeValue] = useState(false);
	const [payMethod, setPayMethod] = useState("Mastercard");
  const [payInfo, setPayInfo] = useState("1111");
  const [paymentInfoData, setPaymentInfoData] = useState({});
  const [paymentMsg, setPaymentMsg] = useState('');
  const [paymentMsgDesc, setPaymentMsgDesc] = useState('');
  const [paymentMsgType, setPaymentMsgType] = useState('');
  const [phoneFormat, setPhoneFormat] = useState('US');
  const [authToken, setAuthToken] = useState(auth);
  const [manual, setManual] = useState(false);
  const [alertModalTitle, setAlertModalTitle] = useState("");	
  const [alertModalBody, setAlertModalBody] = useState("");	
  const [alertModalOpen, setAlertModalOpen] = useState(false);	
  const [alertModalButton, setAlertModalButton] = useState("");
  const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
  const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
  
  const { stateId, cityId, zipId, shopName, shopId } = useParams();
  
  const [card, setCard] = useState(null);
  

  useEffect(() => {
    if (stateId && cityId && zipId && shopName && shopId) {
      setLoading(true);
      document.title = `${shopName} CheckOut | Order Online | ErrandLocal`;
      setStoreId(shopId.toUpperCase());
			
			if(deliveryInfo?.strikeFee){
				setStrikeFee(deliveryInfo?.strikeFee);
				setCoupon(deliveryInfo?.coupon);
			}
			if(deliveryInfo?.strikeValue){				
				setStrikeFee(deliveryInfo?.strikeFee);
				setStrikeValue(deliveryInfo?.strikeValue);
				setCoupon(deliveryInfo?.coupon);
				setCouponAmount(deliveryInfo?.couponAmount);
			}
    } else {
      navigate("/404");
    }
  }, [stateId, cityId, zipId, shopName, shopId]);

  useEffect(() => {
    setCartOrder(cartOrder);
  }, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
  useEffect(() => {
    Object.entries(orderCart).forEach((entry) => {
      const [key, val] = entry;
      if (key === "orderPlaced") {
        if (val.length > 0) {
          setCartLength(val.length);
          setOrderPlaced(val);
        }
      }
      if (key === "fulfillmentType") {
        setFulfillmentType(val);
      }
    });
  }, [orderCart]);
  
  useEffect(() => {
    if (deliveryInfo?.address === undefined) {
      window.location.reload(true);
      return;
    }
	
	if(storeId.toUpperCase() && storeId.length < 11 && storeId.length > 4)
	{
		if(deliveryInfo?.latitude > 0){
			const {latitude, longitude} = deliveryInfo;
			loadData(storeId.toUpperCase(), latitude, longitude);
		}else{
			loadData(storeId.toUpperCase());
		}
		
		setCartStoreName(deliveryInfo?.storeName);
		setAddress(deliveryInfo?.address);
		const deliveryType = deliveryInfo?.type ? deliveryInfo?.type : "Delivery";
		setFulfillmentType(deliveryType);
		setShopHours(deliveryInfo?.hours);
	}
  }, [storeId]);

  useEffect(() => {
    updateTotal();
  }, [orderCart, orderPlaced]);
  
  useEffect(() => {
	let ncards = [];
    for(var i=0; i<cards.length; i++){		
		if(cards[i].cardMain){
			ncards.push({...cards[i], cardMain: false});
		}else{
			ncards.push(cards[i]);
		}
		if(i === cards.length - 1){
			setCards(ncards);
			if(elfirstname !== '' && elfirstname !== 'null' && elfirstname !== false){
				setFirstName(elfirstname);
				setLastName(ellastname);
			}
			setEmail(sessionemail);
			setPhone(deliveryInfo?.phone);			
		}
	}
	
  }, []);

  useEffect(() => {
    async function initCard() {
      // Load the Web Payments SDK script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://web.squarecdn.com/v1/square.js";
      document.head.appendChild(script);

      // Wait for the script to finish loading before continuing
      await new Promise((resolve) => {
        script.addEventListener("load", resolve);
      });

      // Check if the Square object is defined
      if (typeof window.Square === "undefined") {
        console.error("Square object not found");
        return;
      }

      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }

      let payments;
      try {
        payments = window.Square.payments(appId, locationId);
      } catch {
        const statusContainer = document.getElementById(
          "payment-status-container"
        );
        statusContainer.className = "missing-credentials";
        statusContainer.style.visibility = "visible";
        return;
      }
      try {
        const card = await initializeCard(payments);
		if(card){}
		
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    }
   
   if ((ellogin !== '1' || newCard) && shopStoreName === cartStoreName && cartStoreName) {
      initCard();
    }
  }, [shopStoreName, cartStoreName, ellogin, newCard]);
	
  useEffect(() => {	
		window.document.body.style.overflow = "auto";
		if(elfirstname !== '' && elfirstname !== 'null' && elfirstname !== false && ellastname !== '' && ellastname !== 'null' && ellastname !== false){
			setFirstName(elfirstname);
			setLastName(ellastname);
			setManual(false);
			if(ellogin === '1'){
				setEmail(sessionemail);
				setPhone(deliveryInfo?.phone || '1234567890');
			}
		}else if(ellastname !== '' || ellastname !== 'null' || ellastname !== false){
			setFirstName(elfirstname);
			setManual(true);
			if(ellogin === '1'){				
				setEmail(sessionemail);
				setPhone(deliveryInfo?.phone || '1234567890');
			}
		}else if(ellogin === '1'){
			setEmail(sessionemail);
			setPhone(deliveryInfo?.phone || '1234567890');
			setManual(true);
		}
  }, []);
  
  useEffect(() => {		
	window.document.body.style.overflow = alertModalOpen ? "hidden" : "auto";
  }, [alertModalOpen]);
  
  useEffect(() => {
	if(cards.length < 1 && ellogin === '1' && utils.validateEmail(sessionemail)){
		loadEmailData(sessionemail);
	}
  }, []);
  

  // Checkpoint 2.
  async function handlePaymentMethodSubmission(paymentMethod, values) {
    //event.preventDefault();
    let cardButton;
    try {
      cardButton = document.getElementById("card-button");
      // disable the submit button as we await tokenization and make a payment request.
      const token = await tokenize(paymentMethod);
			//const token = 'cnon:CBESECo0KioThwHXr-8SMsI7mK4';
			if(token !== undefined){	  
				//displayPaymentResults("SUCCESS", token);
				createPayment(token, values);
			}
      //console.debug('Payment Success', paymentResults);
    } catch (e) {
      cardButton.disabled = false;
      //displayPaymentResults("FAILURE", e.message);
      //console.error(e.message);
    }
  }

  async function initializeCard(payments) {	
    const card = await payments.card();
    await setCard(card);
    await card.attach("#card-container");
    return card;
  }

  async function createPayment(token, values) {
		let cardButton;
		cardButton = document.getElementById("card-button");
		cardButton.disabled = true;
	
    const newDeliveryData = {
      ...deliveryInfo,
      hours: "",
      firstName: values?.firstName,
      lastName: values?.lastName,
    };
		const dataToPost = {
			...newDeliveryData,
			...values,
			locationId,
			sourceId: token,
			...orderCart,
		};
		
		
		let products = dataToPost?.orderPlaced;
		let newItems = [];
		for (let i = 0; i<products.length; i++){
			let orderItem = {};
			const thisItem = products[i]?.product;
			const thisItemChecked = products[i]?.productChecked;
			const thisItemNote = products[i]?.productNote;
			orderItem.name = thisItem.name;
			orderItem.addons = thisItemChecked;
			orderItem.note = thisItemNote;
			orderItem.menuId = thisItem.id;
			orderItem.uuid = thisItem.cartId;
			orderItem.avatar = thisItem.image;
			orderItem.description = thisItem.description;
			orderItem.currency = thisItem.currency;
			orderItem.qty = thisItem.quantity;
			orderItem.price = thisItem.price;
			orderItem.total = parseFloat(parseFloat(orderItem.price) * parseFloat(orderItem.qty)).toFixed(2);
			newItems.push(orderItem);
		}
		
		const temail = dataToPost?.email || email;
		const target = utils.USER_URL;
		const header = new Headers({
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer '+authToken,
		});		
		
		const posturl = utils.BASE_URL+target; //utils.POST_URL;
		const localtime = await utils.getTime();
		
		const paymentInfo = payInfo;
		const paymentMethod = payMethod;
			
		const body = {'rtype': 'deliverypay', 'email': temail, 'order': newItems, 'subTotal': (parseFloat(paymentInfoData?.subTotal) * 100).toFixed(0), 'deliveryFee': (parseFloat(paymentInfoData?.deliveryFee) * 100).toFixed(0), 'driverTip': (parseFloat(paymentInfoData?.tip) * 100).toFixed(0), 'salesTax': (parseFloat(paymentInfoData?.tax) * 100).toFixed(0), 'serviceCharge': (parseFloat(paymentInfoData?.serviceCharge) * 100).toFixed(0), 'totalNow': (parseFloat(paymentInfoData?.total) * 100).toFixed(0), 'paymentInfo': paymentInfo, 'paymentMethod': paymentMethod, 'coupon': dataToPost?.coupon, 'strikeFee': dataToPost?.strikeFee, 'couponAmount': dataToPost?.couponAmount, 'strikeValue': dataToPost?.strikeValue, 'deliveryType': dataToPost?.type, 'storeId': dataToPost?.storeId, 'storeInstruction': dataToPost?.instruction, 'instructiontodriver': dataToPost?.instructionType+'||'+dataToPost?.instruction, 'now': localtime, 'os': '', 'model': '', 'reftime': '', 'localtime': localtime, 'target': target, 'token': authToken, 'firstName': dataToPost?.firstName, 'lastName': dataToPost?.lastName, 'phone': dataToPost?.phone, sourceId: token, locationId, 'day': dataToPost?.day, 'time': dataToPost?.time, 'aptsuite': dataToPost?.apartment, 'city': dataToPost?.city, 'state': dataToPost?.state, 'zip': dataToPost?.zip, 'country': dataToPost?.country, 'accessCode': dataToPost?.accessCode, 'latitude': dataToPost?.latitude, 'longitude': dataToPost?.longitude, 'address': dataToPost?.address, 'defaultCard': defaultCard };
			
		let obj = JSON.stringify(body);
		const dataPost = await utils.cipherEncode(obj);
		const res = await utils.fetchURL(posturl, 'POST', dataPost, header);
		
		if(res.status !== 200){		
			setPaymentMsg('Process Error');
			setPaymentMsgDesc('Server error: '+res.status+' - Please check back');
			setPaymentMsgType('error');		
			cardButton.disabled = false;
			message.error('Server error: '+res.status+' - Please check back later');
			return;
		}	
		const data = await res.json();		
		let payMsg = document.getElementById('payment_message');
		payMsg.style.display = 'block';
		
		if(data.msg === 'Success'){
			if(payMsg){
				payMsg.style.display = 'block';
				setPaymentMsg('Payment was successful');
				setPaymentMsgDesc(`Your payment have been approved. Please check your email on delivery details`);
				setPaymentMsgType('success');
				localStorage.removeItem('__cart');
				setCartOrder([]);
				setCartLength(0);
				dispatch(setOrderCart({
					fulfillmentType: "Delivery",
					orderPlaced: [],
				}));
				
				if(ellogin === '1'){
					setTimeout(() => { navigate('/pending-orders', { replace: false }) }, 5000);
				}else{
					message.info('Reset Password and login with your email');
					setTimeout(() => { navigate('/pending-orders', { replace: false }) }, 5000);
				}
			}
		}else if(data.msg === 'Failed'){
			if(payMsg){
				payMsg.style.display = 'block';
				setPaymentMsg('Card declined');
				setPaymentMsgDesc(`Your payment was decline. Please try again or use another card`);
				setPaymentMsgType('error');
				cardButton.disabled = false;
			}
		}else if(data?.msg === 'Token Updated' && ellogin === '1'){
			setAuthToken(data?.token);
			await utils.setStorage("__el_data_login_token", data?.token);	
			setPaymentMsg('Process Error');
			setPaymentMsgDesc('Please resubmit payment');
			setPaymentMsgType('error');
			cardButton.disabled = false;
		}else{
			setPaymentMsg('Process Error');
			setPaymentMsgDesc(data.msg);
			setPaymentMsgType('error');
			cardButton.disabled = false;
			if(data?.msg === 'Request have been previously posted'){
				localStorage.removeItem('__cart');
				setCartLength(0);
				setCartOrder([]);
				dispatch(setOrderCart({
					fulfillmentType: "Delivery",
					orderPlaced: [],
				}));
			}
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
	
	
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }  
      //throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status, response) {
    const statusContainer = document.getElementById("payment-status-container");
	
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
    statusContainer.innerHTML = status + " " + response;	
  }

	const handleCoupon = (val) => {
    setCoupon(val);
    const newDeliveryData = { ...deliveryInfo, coupon: val };
    updateDeliveryData(newDeliveryData);
  };	
							
	const applyCoupon = async(val) => {    
		
		const temail = email;
		const target = utils.USER_URL;
		const header = new Headers({
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer '+authToken,
		});		
		
		const posturl = utils.BASE_URL+target; //utils.POST_URL;
							
		const body = {'rtype': 'applycoupon', 'email': temail, 'couponCode': val };
		
		setStrikeFee(false);
		setStrikeValue(false);
		setCouponError('');
		setCouponAmount('');
		
		let obj = JSON.stringify(body);
		const dataPost = await utils.cipherEncode(obj);
		const res = await utils.fetchURL(posturl, 'POST', dataPost, header);
		
		if(res.status !== 200){		
			message.error('Server error: '+res.status+' - Please check back later');
			return;
		}	
		const data = await res.json();
		
		if(data?.msg === 'Success' && data?.type){
			if(data?.type === 'delivery' && data?.value){
				message.success(data?.info);
				setCouponAmount(data?.value);
				setStrikeFee(true);
				return;
			}else if(data?.type === 'delivery' && !data?.value){
				message.success(data?.info);
				setCouponAmount('');
				setStrikeFee(true);
				return;
			}else if(data?.type !== 'delivery' && data?.value){
				message.success(data?.info);
				setStrikeValue(true);
				setCouponAmount(data?.value);
				return;
			}			
		}else if(data?.msg !== 'Success'){
			message.error(data?.msg);
			setCouponError(data?.msg);
			return;
		}
  };


  const loadEmailData = async (email) => {
    try {
      const target =
        utils.USER_CALL + "?email=" + email.toLowerCase();
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
	  const dataToPost = "";
	  const response = await utils.fetchURL(posturl, 'GET', dataToPost, header);
      const statusCode = await response.status;

      if (statusCode === 200) {
		
        const data = await response.json();
        setLoading(false);
		setCards(data.user.paymentCards);
		await dispatch(setPaymentCardData(data.user.paymentCards));
		await dispatch(setSavedCards(JSON.stringify(data.user.paymentCards)));
		
      } else {
        //message.error("Unable to load data. Please try again");
      }
    } catch (e) {
      if (e.message === "NetworkError when attempting to fetch resource.") {
        message.error("Please check your internet connection");
      } else {
        message.error(e.message);
      }
    }
  };

  const loadData = async (storeId, latitude, longitude) => {
    try {
      let addressLocation = '';
      if (storeId.length < 11 && storeId.length > 4) {
				
      } else {
		message.info('Find stores near you');
		setTimeout(()=>{
			navigate('/search', {replace: false});
		}, 5000);
        return;
      }
	  if(latitude){addressLocation = '&latitude=' + latitude + '&longitude=' + longitude}
      const target =
        utils.USER_CALL + "?request=shopinfo&shop=" + storeId.toUpperCase() + addressLocation;
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
	  const dataPost = "";
	  const response = await utils.fetchURL(posturl, 'GET', dataPost, header);
      const statusCode = await response.status;

      if (statusCode === 200) {
        const data = await response.json();
        setGridData(data);
        setLoading(false);
        initParams(data);
      } else {
        //message.error("Unable to load data. Please try again");
      }
    } catch (e) {
      if (e.message === "NetworkError when attempting to fetch resource.") {
        message.error("Please check your internet connection");
      } else {
        message.error(e.message);
      }
    }
  };
  
  
  const displayInfo = (title, description) => {
	setAlertModalTitle(title ? title : 'Title');
	const b = description.split('|');
	let mainbody = '';
	for(let i=0; i<b.length; i++){
		mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
	}
	setAlertModalBody(mainbody+"<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
	setAlertModalButton("1");
	setAlertModalButtonFunc('');
	setAlertModalButtonFunc2('cancel');
	setAlertModalOpen(!alertModalOpen);	
  }

  const initParams = async(altData) => {
    let initData;
    if (altData) {
      initData = altData;
    } else {
      initData = gridData;
    }
    if (Object.keys(initData).length === 0) {
      return;
    }
	
	const shopData = initData?.storeInfo;

	let mph = await utils.getDriveData('mph');
	mph = parseFloat(mph);
	let perHrEarnings = await utils.getDriveData('perHrEarnings');
	perHrEarnings = parseFloat(perHrEarnings);
	let meterPerMile = await utils.getDriveData('meterPerMile');
	meterPerMile = parseFloat(meterPerMile);
	let waitdelay = await utils.getDriveData('waitDelay');
	waitdelay = parseFloat(waitdelay);
	let driverdelay = await utils.getDriveData('driverDelay');
	driverdelay = parseFloat(driverdelay);
	let minTip = await utils.getDriveData('minTip');
	minTip = parseFloat(minTip);
	let mileRadius = await utils.getDriveData('mileRadius');
	mileRadius = parseFloat(mileRadius);
	
	if(deliveryInfo?.latitude){
		const { latitude, longitude } = deliveryInfo;
		
		
		const start = [shopData?.gps?.latitude, shopData?.gps?.longitude];
		const end = [latitude, longitude];
		const distanceInMeters = utils.gis.calculateDistance(start, end);
		const miles = distanceInMeters / meterPerMile;
		if(miles > mileRadius){
			displayInfo("Too far!", `The restaurant is located too far away. Can you please locate a nearby restaurant that is no more than ${mileRadius} miles away?`);
		}
		const deliveryFee = await utils.deliveryFee(miles, mph, perHrEarnings);
		
		let newDeliveryData = {
			...deliveryInfo,
			deliveryFee: deliveryFee,
		};
		updateDeliveryData(newDeliveryData);
	}
	
	const getAddr = await utils.getLocalCookie('elAddr');
	
	if(getAddr?.address && getAddr?.address !== deliveryInfo?.address){
		const cookadd = getAddr?.address;
		const cooklat = getAddr?.latitude;
		const cooklng = getAddr?.longitude;
		
		let newDeliveryData = {...deliveryInfo, address: cookadd, latitude: cooklat, longitude: cooklng};		
		let newErrandData = {...errandInfo, address: cookadd, latitude: cooklat, longitude: cooklng};		
		updateDeliveryData(newDeliveryData);
		updateErrandData(newErrandData);
		setAddress(cookadd);
	}
	
    
    setShopHours(initData?.storeInfo?.days);
    if (utils.convertAddress(shopData?.name) !== shopName) {
      navigate("/404");
      return;
    }
    if (utils.convertAddress(shopData?.state) !== stateId) {
      navigate("/404");
      return;
    }
    if (utils.convertAddress(shopData?.city) !== cityId) {
      navigate("/404");
      return;
    }
    if (utils.convertAddress(shopData?.city) !== cityId) {
      navigate("/404");
      return;
    }	
	
	let newDeliveryData = {
        ...deliveryInfo,
        deliveryFee: shopData?.charges?.deliveryFee,
        currency: shopData?.charges?.currency,
    };
    updateDeliveryData(newDeliveryData);	
	
    setCartStoreName(deliveryInfo?.storeName);
    setTipPercentage(deliveryInfo?.tipPercentage);
    //setTip(deliveryInfo?.tip?.match(/\d+\.\d+/));
    setTip(deliveryInfo?.tip);
    setShopStoreName(shopData?.name);
    setCurrency(shopData?.charges.currency);
    setServiceRate(shopData?.charges?.service);
    setTaxRate(shopData?.charges?.tax);
    setGps(shopData?.gps);
    document.title = `${shopData?.name} CheckOut | Order Online | ErrandLocal`;
    setShopAddress(
      shopData?.address +
        " " +
        shopData?.city +
        ", " +
        shopData?.state +
        " " +
        shopData?.zip
    );

    let storeOperationMsg, msgColor;
    // Get the open and close times from the JSON data
    const { open, close, next, isClose, isBreak, timeZone } =
      shopData?.today;

	const nextOpen = shopData["days"][next]["open"];
    const now = moment.tz(timeZone);
    // Get the abbreviated day of the week
    //const dayOfWeek = now.format("ddd");
	const tday = now.clone().format('MM/DD/YYYY');
	
	let tOpenTime = open.toString();
	tOpenTime = tOpenTime.slice(0, -2) + ':' + tOpenTime.slice(-2);  
	let tCloseTime = close.toString();
	tCloseTime = tCloseTime.slice(0, -2) + ':' + tCloseTime.slice(-2);

	let openTime = moment.tz(tday+' '+tOpenTime, 'MM/DD/YYYY hh:mm', timeZone);
	let closeTime = moment.tz(tday+' '+tCloseTime, 'MM/DD/YYYY hh:mm', timeZone);
	if(closeTime.clone().isBefore(openTime)){
	  const closeDay = now.clone().add(1, 'days').format('MM/DD/YYYY');
	  closeTime = moment.tz(closeDay+' '+tCloseTime, 'MM/DD/YYYY hh:mm', timeZone);
	}
	

    if (isClose) {
      storeOperationMsg = `Opens On ${
        utils.convertToFullDay(next) +
        " " +
        utils.convertTimeTo12HourFormat(nextOpen)
      }`;
      msgColor = "#7f7f7f";
    } else if (isBreak) {
      storeOperationMsg = `Opens On ${
        utils.convertToFullDay(next) +
        " " +
        utils.convertTimeTo12HourFormat(nextOpen)
      }`;
      msgColor = "#7f7f7f";
    } else if (now.isBefore(openTime)) {
      storeOperationMsg = `Opening By ${utils.convertTimeTo12HourFormat(open)}`;
      msgColor = "#7f7f7f";
    } else if (now.isAfter(closeTime)) {
      storeOperationMsg = `CLOSE`;
      msgColor = "#ec4f38";
    } else {
      storeOperationMsg = `OPEN`;
      msgColor = "#2e8540";
      setStoreOpen(true);
    }

    let closetime = parseFloat(close);
    setCloseTime(closetime);
    setShopOperationTime(storeOperationMsg);
    setShopOperationColor(msgColor);
    setShopTimeZone(timeZone);
	
	if(deliveryInfo?.storeName !== shopData?.name){
		setPaymentMsg('Display Error');
		const myLink = <a href="/search/restaurants">Click here to find restaurants near you.</a>;
		setPaymentMsgDesc(<>{myLink}</>);
		setPaymentMsgType('error');
	}
  };

  const AddonData = ({ name, index }) => {
    if (index >= 0) {
      return (
        <li
          className="_30zFN38AZ0D4RcGWr47sgk"
          data-name={`${name}`}
          key={uuidv4()}
        >
          <div>{`${name}`}</div>
        </li>
      );
    } else {
      return null;
    }
  };

  const cartInfoDisplay = (id, cartdata, type) => {
    setCartData(cartdata);
    setCartType(type);
    setOpenOrderModal(true);
  };

  const onCartEdit = async (productId, cartId) => {
    cartInfoDisplay(productId, cartId, "edit");
  };

  const onCartRemove = async (cartId) => {
    let newOrders = [];
    let prevOrders = orderCart["orderPlaced"];
    for (var i = 0; i < prevOrders.length; i++) {
      if (cartId !== prevOrders[i].product.cartId) {
        newOrders = [...newOrders, prevOrders[i]];
      }
    }

    let orderToCart = { ...orderCart, orderPlaced: newOrders };
    updateCartData(orderToCart);
  };

  const updateTotal = async () => {
    let newOrder = 0;
    let newqtyOrder = 0;
    for (var i = 0; i < orderPlaced.length; i++) {
      newOrder = newOrder + parseFloat(orderPlaced[i].product.price);
      newqtyOrder = newqtyOrder + parseFloat(orderPlaced[i].product.quantity);
    }
    setAmountTotal(newOrder);
    setQtyTotal(newqtyOrder);

    setAddress(deliveryInfo?.address);
    const deliveryType = deliveryInfo?.type ? deliveryInfo?.type : "Delivery";
    setFulfillmentType(deliveryType);
  };

  const openLogin = async () => {
    try {
      await setOpenSignIn(true);
    } catch (e) {}
  };

  const updateDeliveryData = async (data) => {
    try {
      setDeliveryInfo(data);
      dispatch(setOrderData(JSON.stringify(data)));
    } catch (e) {}
  };
	
  const updateErrandData = async (data) => {
    try {
      setErrandInfo(data);
      dispatch(setErrandOrderData(JSON.stringify(data)));
    } catch (e) {}
  };
  
  const updateCartData = async (data) => {
    try {
      await dispatch(setOrderCart(JSON.stringify(data)));
      await setCartOrder(data);
      await setCartLength(data["orderPlaced"].length);
      await setOrderPlaced(data["orderPlaced"]);
    } catch (e) {
      message.error(e.message);
    }
  };

  const saveStore = async (data) => {
    try {
      dispatch(setSavedStores(data));
    } catch (e) {}
  };

  const handleTipPercentage = (val) => {
    setTipPercentage(val);
    const newDeliveryData = { ...deliveryInfo, tipPercentage: val };
    updateDeliveryData(newDeliveryData);
		const newErrandData = { ...errandInfo, tipPercentage: val };
    updateErrandData(newErrandData);
  };

  const handleCustomTip = (val) => {
    setTip(val);
    const newDeliveryData = { ...deliveryInfo, tip: val };
    updateDeliveryData(newDeliveryData);
    const newErrandData = { ...errandInfo, tip: val };
    updateErrandData(newErrandData);
  };

  const onFinish = async () => {	
	setPaymentMsg('');
	setPaymentMsgDesc(``);
	setPaymentMsgType('');			
	let cardButton = document.getElementById("card-button");
	let payMsg = document.getElementById("payment_message");
	payMsg.style.display = 'none';
	let myLink;
	
	let mph = await utils.getDriveData('mph');
	mph = parseFloat(mph);
	let perHrEarnings = await utils.getDriveData('perHrEarnings');
	perHrEarnings = parseFloat(perHrEarnings);
	let meterPerMile = await utils.getDriveData('meterPerMile');
	meterPerMile = parseFloat(meterPerMile);
	let waitdelay = await utils.getDriveData('waitDelay');
	waitdelay = parseFloat(waitdelay);
	let driverdelay = await utils.getDriveData('driverDelay');
	driverdelay = parseFloat(driverdelay);
	let minTip = await utils.getDriveData('minTip');
	minTip = parseFloat(minTip);
	let mileRadius = await utils.getDriveData('mileRadius');
	mileRadius = parseFloat(mileRadius);
	
	if(shopOperationTime !== 'OPEN' && deliveryInfo?.day === 'ASAP')
	{
		setPaymentMsg('Process Error');
		setPaymentMsgDesc('Please order ahead. The store currently closed.');
		setPaymentMsgType('error');
		cardButton.disabled = false;
		payMsg.style.display = 'block';
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setAddressModalOpen(true);
		return;
	}	
	if(deliveryInfo?.latitude)
	{
		const { latitude, longitude } = deliveryInfo;
		
		
		
		const start = [gps?.latitude, gps?.longitude];
		const end = [latitude, longitude];
		const distanceInMeters = utils.gis.calculateDistance(start, end);
		const miles = distanceInMeters / meterPerMile;		
		const deliveryFee = await utils.deliveryFee(miles, mph, perHrEarnings);
		
		if(miles > mileRadius){			
			setPaymentMsg('Process Error');
			myLink = <a href="/search/restaurants">Click here to find restaurants near you</a>;
			setPaymentMsgDesc(<>Restaurant is too far. {myLink}</>);
			setPaymentMsgType('error');
			cardButton.disabled = false;
			payMsg.style.display = 'block';
			window.scrollTo({ top: 0, behavior: 'smooth' });
			displayInfo("Too far!", `The restaurant is located too far away. Can you please locate a nearby restaurant that is no more than ${mileRadius} miles away?`);
			return;
		}		
  }	
	if(cartLength < 1){
		setPaymentMsg('Process Error');
		myLink = <a href="/search">Click here to find restaurants near you</a>;
		setPaymentMsgDesc(<>Your cart is empty. {myLink}</>);
		setPaymentMsgType('error');
		cardButton.disabled = false;
		payMsg.style.display = 'block';
		window.scrollTo({ top: 0, behavior: 'smooth' });
		return;
	}
	if(amountTotal < 1){
		setPaymentMsg('Process Error');
		myLink = <a href="/search">Click here to find restaurants near you</a>;
		setPaymentMsgDesc(`Invalid order`);
		setPaymentMsgType('error');
		cardButton.disabled = false;
		payMsg.style.display = 'block';
		window.scrollTo({ top: 0, behavior: 'smooth' });
		return;
	}
    setPost(true);
    const checkEmail = await utils.validateEmailInput(email);
    const tipAmount = deliveryInfo?.tip;	
    setEmailError(checkEmail);
		setTipError("");
	
	if(parseFloat(tipAmount) < minTip){
		setTipError("Error");
		setPaymentMsg('Process Error');
		setPaymentMsgDesc(`Minimum tip is ${minTip}`);
		setPaymentMsgType('error');
		cardButton.disabled = false;
		payMsg.style.display = 'block';
		window.scrollTo({ top: 0, behavior: 'smooth' });
		return;
	}
	
    if (firstName && lastName && !checkEmail && phone) {
      const values = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      };
	  if(ellogin !== '1' || (newCard && ellogin === '1')){
			await handlePaymentMethodSubmission(card, values);
	  }else if(defaultCard && ellogin === '1'){		
			await createPayment(defaultCard, values);
	  }else if(!defaultCard && ellogin === '1'){		
			setPaymentMsg('Process Error');
			setPaymentMsgDesc(`Please choose payment card`);
			setPaymentMsgType('error');
			cardButton.disabled = false;
			payMsg.style.display = 'block';
			window.scrollTo({ top: 0, behavior: 'smooth' });
	  }
    }else{		
		setPaymentMsg('Process Error');
		setPaymentMsgDesc(`Please check the required fields`);
		setPaymentMsgType('error');
		cardButton.disabled = false;
		payMsg.style.display = 'block';
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#3e5725',
  };

  const handleDefaultCard = (e) => {
    setDefaultCard(e.target.value);
	setNewCard(false);
	for(var i=0; i<cards.length; i++){		
		if(cards[i].id === e.target.value){
			setPayInfo(cards[i].cardEnding);
			setPayMethod(cards[i].cardType);
		}
	}
	
  };

  const handleNewCard = async () => {
    try {
	    let ncards = [];
		for(var i=0; i<cards.length; i++){		
			if(cards[i].cardMain){
				ncards.push({...cards[i], cardMain: false});
			}else{
				ncards.push(cards[i]);
			}
			if(i === cards.length - 1){
				setCards(ncards);
				setDefaultCard("");
			}
		}
		setNewCard(!newCard);      
    } catch (e) {}
  };

  const handleChangePhoneFormat = () => {
	const val = phoneFormat === 'US' ? 'NG' : 'US';
	setPhoneFormat(val);
  }

  if (shopStoreName) {
    return (
      <main
        role="main"
        className="bkzPZTxP-TFLWInTcLG0E"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div aria-hidden="true" className="ThwrxgtcmnwqtSWRYuHD0">
          <span className="_1eXXGcEHjyQF0xRM14r9HC _125ifHKgzgBJTAaupIqJjX">
            <span className="_3ouqELkl11SiS8A3aYXY9j">Checkout</span>
            <h2 className="_1UJmr8r-9CVonU_bipit_V">{`${
              shopStoreName === cartStoreName ? cartStoreName : ""
            }`}</h2><br/>
			<span style={{display: 'block', fontFamily: 'Montserrat', fontWeight: 600, color: 'rgb(62, 87, 37)'}}>{`${
              shopStoreName === cartStoreName ? shopAddress : ""
            }`}</span>
          </span>

		  <Space id="payment_message" direction="vertical" style={{ width: '100%', display: `${
              shopStoreName === cartStoreName ? "none" : "block"
            }` }}>
			<Alert				
				message={paymentMsg}
				description={paymentMsgDesc}
				type={paymentMsgType}
			/>
		  </Space>
          <div className="GV-4U04e-OPDRfnLwJZrA _3vnr7L1M-rxrDc-wQY15Rd _14rnwwwsXq4gI8JwNsN3rt">
            <h2 className="_1oS3fILK4ycPhObmii7Xy5 _1FGHAS10SJ_nUB2E2cfN5o">
              Details
            </h2>
            <div className="sivN0QB-MWF-_Ey25UGyT _1FGHAS10SJ_nUB2E2cfN5o">
              <div className="_3qb-vJ1rjTtHeMxHTM7DQM">
                {shopStoreName === cartStoreName && (
                  <>
                    <CheckOutAddressModal
                      fulfillmentType={fulfillmentType}
                      setFulfillmentType={setFulfillmentType}
                      address={address}
                      setAddress={setAddress}
                      openLogin={openLogin}
                      deliveryInfo={deliveryInfo}
                      errandInfo={errandInfo}
                      shopHours={shopHours}
                      updateDeliveryData={updateDeliveryData}
                      updateErrandData={updateErrandData}
                      addressModalOpen={addressModalOpen}
                      setAddressModalOpen={setAddressModalOpen}
                      shopTimeZone={shopTimeZone}
					  gps={gps}
                    />
                    <CheckOutInstructionModal
                      deliveryInfo={deliveryInfo}
                      updateDeliveryData={updateDeliveryData}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="GV-4U04e-OPDRfnLwJZrA _3vnr7L1M-rxrDc-wQY15Rd">
            <h2 className="_1oS3fILK4ycPhObmii7Xy5">Contact</h2>
			
            {(ellogin !== '1' || manual) && (<ContactSection
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              post={post}
              emailError={emailError}
			  manual={manual}
			  phoneFormat={phoneFormat}
			  handleChangePhoneFormat={handleChangePhoneFormat}
            />)}
			
			{ellogin === '1' && !manual && (
			<div className="sivN0QB-MWF-_Ey25UGyT">
			  <div data-testid="userForm" className="">
				<div className="_1Hwy0KjPHG2LqgsRmhddq6">
				  <div className="_1EoZthREuSFRJRNxo0vkXz">
					<div
					  className="yNIm2lvE_j8Du8jBoXORx"
					  data-testid="text_input_wrapper"
					>
					<span style={radioStyle}>{`${firstName} ${lastName}`}</span>
					</div>
				  </div>
				</div>
			  </div>
			</div>
			)}
			
          </div>

          <div className="GV-4U04e-OPDRfnLwJZrA _3vnr7L1M-rxrDc-wQY15Rd">
            <h2 className="_1oS3fILK4ycPhObmii7Xy5">
              Payment
              <span className="_2cSvW8v2jbyUfNR57xA3JZ">
                Secure
                <svg
                  className="_1HwqfywdQZ6aGbCZptSHjl"
                  width="18"
                  height="22"
                  viewBox="0 0 18 22"
                  aria-hidden="true"
                >
                  <title>Icon Secure</title>
                  <g fill="none" fillRule="evenodd">
                    <path
                      stroke="#4C4C4C"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.705"
                      d="M6.185 9.116h10.668v11.805h-16V9.116h5.333"
                    ></path>
                    <path
                      stroke="#4C4C4C"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.843"
                      d="M4.161 8.948V4.934c0-1.319.614-4.013 4.691-4.013 3.98 0 4.691 2.723 4.691 4.013v4.014"
                    ></path>
                    <path
                      fill="#4C4C4C"
                      d="M7.074 13.775c0-1.003.796-1.816 1.778-1.816s1.778.813 1.778 1.816c0 1.003-.796 1.816-1.778 1.816s-1.778-.813-1.778-1.816"
                    ></path>
                    <path
                      stroke="#4C4C4C"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.797"
                      d="M8.853 14.434v2.705"
                    ></path>
                  </g>
                </svg>
              </span>
            </h2>
						
			<div className="sivN0QB-MWF-_Ey25UGyT">
			  <div data-testid="userForm" className="">
				<div className="_1Hwy0KjPHG2LqgsRmhddq6">
				  <div className="_1EoZthREuSFRJRNxo0vkXz">
					<div
					  className="yNIm2lvE_j8Du8jBoXORx"
					  data-testid="text_input_wrapper"
					>
					
					{ellogin === '1' && (
					  <><div style={{...radioStyle, textDecoration: 'underline'}}>{`${newCard ? 'NEW CARD     ' : 'NEW CARD     '}`} 
					  {newCard && card && (<Button
						icon={<MinusOutlined />}
						shape={"circle"}
						size={"small"}						
						className={"payButton"}
						style={{cursor: 'pointer'}}
						onClick={()=> handleNewCard()}
						danger
					  />)}
					  {newCard && !card && (<Button
						icon={<PoweroffOutlined />}
						shape={"circle"}
						size={"small"}						
						className={"payButton"}
						loading
					  />)}
					  {!newCard && (<Button
						icon={<PlusOutlined />}
						shape={"circle"}
						size={"small"}
						className={"payButton"}
						style={{cursor: 'pointer'}}
						onClick={()=> handleNewCard()}
						type={"primary"}
					  />)}</div><br/></>
					)}
					
					{newCard && (<>
					  <div>
						<div>
						  <div id="card-container"></div>
						  <div id="payment-status-container"></div>
						</div>
					  </div></>
					)}
					
					{ellogin !== '1' && (<>
					  <div style={radioStyle}>Credit/Debit card</div><br/>
					  <div>
						<div>
						  <div id="card-container"></div>
						  <div id="payment-status-container"></div>
						</div>
					  </div></>
					)}
					
					{ellogin === '1' && cards.length > 0 && (<><div style={{...radioStyle, textDecoration: "underline"}}>SAVED CARDS</div><Radio.Group onChange={handleDefaultCard} value={defaultCard}>
					  {cards.map((thiscard) => (
						<Radio style={radioStyle} key={thiscard.id} value={thiscard.id}>
						  {`${thiscard.cardType} Ends in ${thiscard.cardEnding}`}
						</Radio>
					  ))}
					</Radio.Group></>)}					
					</div>
				  </div>
				</div>
			  </div>
			</div>
			
          </div>

          {cartLength > 0 && (<div className="GV-4U04e-OPDRfnLwJZrA _3qQrZAgQQ3yUnrxWOfXrjD">
            <h2 className="_1oS3fILK4ycPhObmii7Xy5">Your items</h2>
            <div className="sivN0QB-MWF-_Ey25UGyT">
              <div>
                {cartStoreName === shopStoreName && cartLength > 0 && (
                  <div
                    data-name="cart.products"
                    className="_1pclOaC04dlaY0UHl3zyWt"
                  >
                    {orderPlaced.map((orderData, index) => (
                      <div
                        className="B7a69Q2IoGnNLBxAiDcbR"
                        data-name="cart.product"
                        style={{ cursor: "pointer" }}
                        key={uuidv4()}
                      >
                        <div className="_25s2omhUhQfcHCtikP0XBk" key={uuidv4()}>
                          <div
                            className="_8Tpso4fqXWiM2ktK2uB2K"
                            data-name="cart.product.quantity"
                            key={uuidv4()}
                          >{`${orderData?.product?.quantity}x`}</div>
                          <div
                            className="_28IBU17s5t7XL-a2xuGSRc"
                            key={uuidv4()}
                          >
                            <div
                              className="_3KjnRbm5_nWZGCD1uQPzjP"
                              key={uuidv4()}
                            >
                              <div
                                className="h8oaw1War6h7kCEs3RATY"
                                key={uuidv4()}
                                onClick={() =>
                                  onCartEdit(
                                    orderData?.product?.id,
                                    orderData?.product?.cartId
                                  )
                                }
                              >
                                <div
                                  className="_3KsO2l-LNdW3dDO2uuHK6N"
                                  data-name="cart.product.name"
                                  key={uuidv4()}
                                >{`${orderData?.product?.name}`}</div>
                                <div
                                  className="_3T8zb1IA4akzgtCDPmtwOd"
                                  key={uuidv4()}
                                ></div>
                                <div
                                  className="_3eybxaF9nXFCPI55vgY_Gx"
                                  data-name="cart.product.price"
                                  key={uuidv4()}
                                >{`${orderData?.product?.displayprice}`}</div>
                              </div>
                              <div
                                className="_3qdtGWGgRqWfao-VslXx0u"
                                key={uuidv4()}
                                onClick={() =>
                                  onCartEdit(
                                    orderData?.product?.id,
                                    orderData?.product?.cartId
                                  )
                                }
                              >
                                <div
                                  className="_3ylDuZwTTDugvTa4GQcSov"
                                  key={uuidv4()}
                                >
                                  <ul
                                    className="MIwqEkDopWWLPJ0sgK7Sa"
                                    data-name="product_detail_list"
                                    key={uuidv4()}
                                  >
                                    {orderData?.productChecked.map(
                                      ({ name, id }, index) => (
                                        <AddonData
                                          name={name}
                                          id={id}
                                          index={index}
                                          key={uuidv4()}
                                        />
                                      )
                                    )}
                                    {orderData?.productNote && (
                                      <li
                                        className="_30zFN38AZ0D4RcGWr47sgk"
                                        data-name={`product-note`}
                                        key={uuidv4()}
                                      >
                                        <div>{`${orderData?.productNote}`}</div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="_23zWnWb43MZ_hovn0xDJL6">
                                <button
                                  data-name="edit_item"
                                  className="_2w2jqstPutCrrmGTTRZg5X"
                                  onClick={() =>
                                    onCartEdit(
                                      orderData?.product?.id,
                                      orderData?.product?.cartId
                                    )
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  data-name="delete_item"
                                  className="_2w2jqstPutCrrmGTTRZg5X"
                                  onClick={() =>
                                    onCartRemove(orderData?.product?.cartId)
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>)}
		</div>
		

        <div aria-hidden="true" className="_3miEcpP8Qv2aH97wlosF4O">
          {shopStoreName === cartStoreName && (
            <PaymentSummarySection
              subTotal={`${(amountTotal / 100).toFixed(2)}`}
              currency={`${currency}`}
              taxrate={taxrate}
              servicerate={servicerate}
              latitude={`${deliveryInfo?.latitude}`}
              longitude={`${deliveryInfo?.longitude}`}
              gps={gps}
              tip={tip}
              tipPercentage={tipPercentage}
              handleTipPercentage={handleTipPercentage}
              handleCustomTip={handleCustomTip}
              shopStoreName={shopStoreName}
              cartStoreName={cartStoreName}
              card={`${card}`}
              defaultCard={`${defaultCard}`}
              onFinish={onFinish}
              deliveryInfo={deliveryInfo}
              updateDeliveryData={updateDeliveryData}
							tipError={tipError}
							setPaymentInfoData={setPaymentInfoData}
							displayFeeInfo={displayInfo}
							couponError={couponError}
							coupon={coupon}
							couponAmount={couponAmount}
							handleCoupon={handleCoupon}
							applyCoupon={applyCoupon}
							strikeValue={strikeValue}
							strikeFee={strikeFee}
            />
          )}
        </div>

        <div aria-hidden="true"></div>

        {openOrderModal && (
          <OrderModal
            openOrderModal={openOrderModal}
            setOpenOrderModal={setOpenOrderModal}
            setCartSubTotal={setCartSubTotal}
            cartSubTotal={cartSubTotal}
            savedStores={savedStores}
            saveStore={saveStore}
            cartData={cartData}
            cartType={cartType}
            loading={setLoading}
            refId={storeId}
            gridData={gridData}
            orderCart={orderCart}
            updateCartData={updateCartData}
            setCheckOutError={setCheckOutError}
            orderModalStyle={{
              width: 0,
              height: 0,
              left: -1000,
              position: "absolute",
            }}
          />
        )}		
		
		{alertModalOpen && (<AlertModal setAlertOpenModal={setAlertModalOpen} alertModalTitle={alertModalTitle} alertModalBody={alertModalBody} alertModalButton={alertModalButton} setAlertModalButtonFunc={()=> setAlertModalOpen(false)} setAlertModalButtonFunc2={()=> setAlertModalOpen(false)} alertType="alert"/>)}
      </main>
    );
  } else {
    return (
      <main
        role="main"
        className="bkzPZTxP-TFLWInTcLG0E"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div aria-hidden="true" className="ThwrxgtcmnwqtSWRYuHD0">
          <main id="mainContent" aria-label="Menu content">
            <div></div><br/><br/><br/>
            <div>
              <div className="_ruof1q">
                <div className="_86m4">
                  <div className="_1jx0i45">
                    <Skeleton.Input active />
                    <Skeleton.Input active />
                    <Skeleton.Input active />
                    <div id="card-container"></div>
                    <div id="payment-status-container"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="_as2sev">
              <div></div>
              <Skeleton />
              <Divider />
              <Skeleton />

              <Divider orientation="left"></Divider>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Skeleton active />
              </div>
              <Divider />
            </div>
          </main>
        </div>
        <div aria-hidden="true" className="_3miEcpP8Qv2aH97wlosF4O"></div>
      </main>
    );
  }
  
};

export default CheckOutScreen;
